import BaseAction from './BaseAction';
import AddGeometryAction from '@/Actions/AddGeometry';
import UIModeAction from '@/Actions/UIMode';
import { getPositionFromSvgViewbox } from '@/Utility/viewbox';
import { selectSVGViewbox } from '@/Redux/Slices/ViewportSlice';
import {
  setLoading,
  updateCustomAnchorOption,
} from '@/Redux/Slices/SherpaContainerSlice';
import { toggleFileImport } from '@/Redux/Slices/UISlice';
import { selectTessellationFeatureFlag } from '@/Redux/Slices/FeatureFlagsSlice';
import {
  setImportedGroup,
  selectImportedGroup,
  setImportPosition,
  selectImportPosition,
} from '@/Redux/Slices/ImportSlice';
import {
  updateKeyAndValue,
  clone,
  createSVGGroupAndImportPositionFromSvg,
  SvgGroupUpdateKey,
} from '@/Geometry/SvgGroupOps';
import { add } from '@/Geometry/PointOps';
import { mergeAABBArray, getSizeAndCenterFromAABB } from '@/Geometry/AABBOps';
import { convertDxfToSvg } from '@/Geometry/DxfConverter';
import { transform, updateSvgSourceTransform } from '../Geometry/BasePathOps';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { multiplyMatrix33 } from '@shapertools/sherpa-svg-generator/Matrix33';
import {
  createTranslateMtx,
  invert,
} from '@shapertools/sherpa-svg-generator/Matrix33';

import getShaperRootXmlAttributes from '@/Geometry/getShaperRootXmlAttributes';
import {
  ImportOptions,
  SvgTypeOptions,
} from '@/Geometry/ShaperSvgXmlAttributes';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import removeOriginCustomAnchorFromImportedWorkpiece from '@/Geometry/removeOriginCustomAnchorFromImportedWorkpiece';
import { isSourceSvgObject } from '@shapertools/sherpa-svg-generator/Path';

/*
  Import flow
   dxf  --> svg
    
   SvgGroupOps.createSvgGroupFromSvg(svg) -- make group (or alternatively just paths)
    //Apply join, deduplicate, close repairs here

    Store geometry in ImportSlice

    setMode to import,
    ImportUI gets geometry from slice

    ImportUI.cancel:
      clearImportGeom() 
      setMode(default)

    ImportUI.placeGroup
      CanvasOps.addGroupsToCanvas(importedGroup)
      setMode(default)

    ImportUI.placeSeparately
      start loading spinner
      call splitAndPlaceImportedGroups{
        getImportedGroups
        start webworker to split into multiple groups -- see PathRepair.worker.js or PathRepair.js for synchronous version
      }

      worker.onmessage = this.groupPathsComplete({data})

      groupPathsComplete{
        translates each split group so relative positions of groups remains unchanged
        returns cloned groups
      }

      groups = pathSets.map(ps => SvgGroupOps.createSvgGroup(ps))
      CanvasOps.addGroupsToCanvas(groups)
      setMode(default)
*/

const isSvgImportAnOriginWorkpieceWithDefaultPosition = (svgStr: string) => {
  const { svgType, importOptions } = getShaperRootXmlAttributes(svgStr);

  const isOriginWorkpiece = !!svgType?.includes(SvgTypeOptions.originWorkpiece);

  const isDefaultPosition = !!importOptions?.includes(
    ImportOptions.ViewBoxOriginIsGridOrigin
  );

  return isOriginWorkpiece && isDefaultPosition;
};

export default class ImportGeometryAction extends BaseAction {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(...args: ConstructorParameters<typeof BaseAction>) {
    super(...args);
    // since we are no longer attempting to group paths on import
    // we don't have to put Path Repair in a web worker :)
    // this.worker = new PathRepairWorker();
    // this.worker.onmessage = this.groupPathsComplete.bind(this);
  }

  getViewboxPosition = () => {
    const viewbox = this.useSelector(selectSVGViewbox);
    return getPositionFromSvgViewbox(viewbox);
  };

  getImportedGroup = () => {
    // Value from selector is immutable, but we may need to mutate to adjust position, so deep clone it.
    return structuredClone(this.useSelector(selectImportedGroup));
  };

  placeImportedGroups(
    importedGroups: SvgGroup[] = [this.getImportedGroup()].filter(
      (g): g is SvgGroup => g !== undefined
    )
  ) {
    //Logic for positioning: 0, 0 if position is on, otherwise center of screen.

    // Until Shawn or Adi says otherwise, Origin workpieces should ALWAYS be placed with the grid Origin at Studio's origin.
    const importPosition = this.useSelector(selectImportPosition);
    const defaultPosition = this.getViewboxPosition();

    // If the import position is defined, it suggests that the SVG is considered an Origin workpiece.
    const isOriginWorkpiece = Boolean(importPosition);

    // Assign the actual position to be used based on whether the SVG is an Origin workpiece.
    const position = importPosition !== null ? importPosition : defaultPosition;
    const positionAsPoint = new Point(position.x, position.y);

    const hasTessellationFeatureFlag = this.useSelector(
      selectTessellationFeatureFlag
    );
    importedGroups.forEach((ig) => {
      updateKeyAndValue(
        ig,
        {
          key: SvgGroupUpdateKey.RelativePosition,
          value: positionAsPoint,
        },
        hasTessellationFeatureFlag
      );
    });

    // Set the custom anchor option for the canvas when importing an Origin workpiece
    this.dispatch(updateCustomAnchorOption(isOriginWorkpiece));
    const addGeometryAction = this.createAction(AddGeometryAction);
    addGeometryAction.placeImportedGroups(importedGroups);
    const uiModeAction = this.createAction(UIModeAction);
    uiModeAction.toDefault();
  }

  splitAndPlaceImportedGroup(attemptGrouping = false) {
    const importedGroup = this.getImportedGroup();
    if (!importedGroup) {
      return;
    }
    // if(attemptGrouping){
    //   this.worker.postMessage(importedGroup);
    // }
    this.groupPathsComplete({
      data: { importedGroup, splitPathSets: importedGroup.basePathSet },
    });
  }

  groupPathsComplete(msg: {
    data: { importedGroup: SvgGroup; splitPathSets: BasePath[] };
  }) {
    let { importedGroup, splitPathSets } = msg.data;
    const splitPathSetsAsArrayOfArrays = splitPathSets.flat().map((x) => [x]);
    const pathSetsPositions = splitPathSetsAsArrayOfArrays.map((pathSet) => {
      const pathsAABBs = pathSet.map((path) => path.AABB);
      const pathSetAABB = mergeAABBArray(pathsAABBs);
      return getSizeAndCenterFromAABB(pathSetAABB).centerPosition;
    });
    const splitGroups = splitPathSetsAsArrayOfArrays.map((pathSet, idx) => {
      //Set each split group's position to be the center of the pathSet's AABB
      const position = pathSetsPositions[idx];

      //Transform each split group's path by -pathSetPosition
      const pathMtx = invert(createTranslateMtx(position));
      const transformedPathSet = pathSet
        .map((path) => transform(path, pathMtx))
        //Need to preserve this relative position transform to use later when retessellating
        .map((path) =>
          isSourceSvgObject(path.sourceSvg)
            ? updateSvgSourceTransform(
                path,
                multiplyMatrix33(pathMtx, path.sourceSvg.sourceTransform)
              )
            : path
        );

      return clone(
        {
          ...importedGroup,
          position: add(position, importedGroup.position),
        },
        transformedPathSet
      );
    });
    this.placeImportedGroups(splitGroups);
  }

  importSVG(
    svg: string,
    {
      fromTrace = false,
      skipImportScreen = false,
    }: {
      fromTrace?: boolean;
      skipImportScreen?: boolean;
    } = {}
  ) {
    this.dispatch(toggleFileImport(true));
    const originWorkpiece =
      isSvgImportAnOriginWorkpieceWithDefaultPosition(svg);

    // Remove Origin workspace custom anchor points, if any, because we don't want to duplicate this on export
    const svgStr = originWorkpiece
      ? removeOriginCustomAnchorFromImportedWorkpiece(svg)
      : svg;

    const { svgGroup, importPosition } = createSVGGroupAndImportPositionFromSvg(
      {
        useGroupSize: true,
        svgStr: svgStr,
        repair: true,
        originWorkpiece,
      }
    );

    this.dispatch(
      setImportedGroup({
        importGroup: svgGroup,
        fromTrace,
      })
    );

    // Only use the import position if the svg is an Origin workpiece, otherwise set to null and use the default positioning rules for imported SVG files
    this.dispatch(setImportPosition(originWorkpiece ? importPosition : null));
    if (!skipImportScreen) {
      this.dispatch(setLoading(false));
      const uiModeAction = this.createAction(UIModeAction);
      uiModeAction.toImport();
    }
  }

  importDXF(dxf: string, position = this.getViewboxPosition()) {
    const convertedSVG = convertDxfToSvg(dxf);
    if (convertedSVG) {
      this.importSVG(convertedSVG);
    }
  }
}
