import { updateCanvasAABB } from '@/Geometry/CanvasOps';
import { Canvas } from '@shapertools/sherpa-svg-generator/Canvas';
import { Patch } from 'immer';

const createDeletePatch = (
  id: string,
  canvasObj: Canvas,
  path: string[]
): [Patch, Patch, number] | [null, null, number] => {
  const svgGroup = canvasObj.svgGroupSet.find((s) => s.id === id);
  if (svgGroup) {
    const elementPosition = canvasObj.svgGroupSet.indexOf(svgGroup);
    if (elementPosition > -1) {
      // remove patch
      const patch = { op: 'remove', path: [...path, elementPosition] } as Patch;

      // inverse patch is to add the object back at element position
      const inversePatch = {
        op: 'add',
        path: [...path, elementPosition],
        value: svgGroup,
      } as Patch;
      return [patch, inversePatch, elementPosition];
    }
  }

  return [null, null, -1];
};

export const produceDeletePatches = (
  ids: string[] | string,
  canvasObj: Canvas,
  path: string[]
) => {
  const patches = [] as Patch[],
    inversePatches = [] as Patch[];

  const idsArray = Array.isArray(ids) ? ids : [ids];
  const priorAABB = canvasObj.AABB;

  idsArray.reduce((canvas, deleteId) => {
    const [patch, inversePatch, elementPosition] = createDeletePatch(
      deleteId,
      canvas,
      path
    );
    if (elementPosition > -1 && patch && inversePatch) {
      patches.push(patch);
      inversePatches.unshift(inversePatch);

      canvas.svgGroupSet.splice(elementPosition, 1);
    }
    return canvas;
  }, canvasObj);

  const newAABB = updateCanvasAABB(canvasObj);

  if (
    newAABB.size.x !== priorAABB.size.x ||
    newAABB.size.y !== priorAABB.size.y
  ) {
    patches.push({
      op: 'replace',
      path: ['canvas', 'AABB'],
      value: updateCanvasAABB(canvasObj),
    });
    inversePatches.unshift({
      op: 'replace',
      path: ['canvas', 'AABB'],
      value: priorAABB,
    });
  }

  if (patches.length === 0) {
    // WHY??? But tests say it must be so
    patches.push(null as any);
    inversePatches.push(null as any);
  }

  return [patches, inversePatches];
};
