import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

// consts
import {
  isEnterKey,
  isEscKey,
  isRedoInput,
  isUndoInput,
} from '@/Utility/keyboard';

// helpers
import { cancelEvent } from '@/Utility/events';
import { asIntegerString, asNumericString } from '@/Utility/sanitize';

// selectors
import { selectDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';

// components
import Icon from '@/Styles/Icons/Icon';
import evaluateExpression from '../../../Helpers/EvaluateExpression';
import { useSelector } from 'react-redux';
import selectAll from '@/Utility/select-all';
import { useFloatingPanelContext } from '../FloatingPanel';
import UndoRedoAction from '@/Actions/UndoRedoAction';
import { useAction } from '@/Actions/useAction';

export default function Input(props) {
  const ref = useRef();
  const [selected, setSelected] = useState();
  const displayUnits = useSelector(selectDisplayUnits);
  const undoRedoAction = useAction(UndoRedoAction);

  // test
  if (props.forwardRef) {
    props.forwardRef.current = ref.current;
  }

  // computed
  const autoFocus = 'focus' in props || 'autoFocus' in props;
  const commitOnBlur = props.commitOnBlur !== false;
  const isStepper = 'step' in props;
  const isWorkspaceId = 'workspaceId' in props;
  const sanitize =
    'int' in props
      ? asIntegerString
      : 'number' in props
      ? asNumericString
      : props.sanitize || ((val) => val);

  const { type = 'text' } = props;
  const attrs = type === 'number' ? { step: 'any' } : {};
  const dataCy = props.dataCy;
  const disabled = props.disabled || useFloatingPanelContext() || false;

  // state
  const [display, setDisplay] = useState(props.value);
  const [focused] = useState(!autoFocus);

  // when first showing, display the default value, if any
  useLayoutEffect(() => {
    setTimeout(() => {
      if (props.defaultValue) {
        ref.current.textContent = props.defaultValue;
      }

      if (props.autoFocus) {
        ref.current.focus();
      }
    });
  }, []);

  // attempts to focus on a properties panel input
  // used when blurring a text field
  function refocusPropertiesPanelInput() {
    // this may need to be adapted, but at the moment the only things
    // that appear to get focus when tabbing out of the properties panel
    // is the body or an iframe
    if (/(body|iframe)/i.test(document.activeElement?.tagName)) {
      document.querySelector(`.properties-panel--input--field`)?.focus();
    }
  }

  // update the DOM element
  function replaceInput(str) {
    if (!ref.current) {
      return;
    }

    ref.current.innerText = str;
  }

  // get the input value
  function getValue() {
    let text = ref.current?.innerText;

    // if this should allow calculations
    if ('calculate' in props) {
      text = evaluateExpression(
        text,
        'ignoreUnits' in props ? null : displayUnits
      );
    }

    return sanitize(text);
  }

  function handleRedo() {
    undoRedoAction.redo();
  }

  function handleUndo() {
    undoRedoAction.undo();
  }

  // replace the input value
  function setValue(value) {
    const str = sanitize(value);
    replaceInput(str);
  }

  // resets the input
  function onReset(value) {
    setDisplay(value);
    replaceInput(value);
  }

  // notify of changes
  function onApplyValue(event) {
    // if nothing changed, then there's no reason
    // to update anything
    if (ref.current?.innerText === display) {
      return;
    }

    const didPressEnter = event.keyCode === 13;
    let value = getValue();
    if ('max' in props) {
      value = Math.min(value, props.max);
    }

    if ('min' in props) {
      value = Math.max(value, props.min);
    }

    setValue(value);

    // nothing changed
    if (value === props.value || value === '') {
      onReset(props.value);
      return;
    }

    // notify of the change
    if (props.onCommit) {
      props.onCommit(value, event, { didPressEnter });
    }

    props.onAfterCommit?.(event);
  }

  // handles stepping an input
  function onStep(dir, event) {
    const isMixed = getValue() === 'mixed';
    if (!isMixed) {
      const current = parseFloat(getValue());
      // update the value
      let value = current + dir * props.step;
      if ('max' in props) {
        value = Math.min(value, props.max);
      }

      if ('min' in props) {
        value = Math.max(value, props.min);
      }

      if ('precision' in props) {
        value = parseFloat(value.toPrecision(props.precision));
      }

      // if this changed, update the value
      if (current !== value && props.onCommit) {
        onReset(value);
        props.onCommit(value, event);
      }
    }
  }

  // handle defocus
  function onBlur(event) {
    if (!disabled) {
      setSelected(false);

      if (!commitOnBlur) {
        replaceInput(props.value);
        setDisplay(props.value);
        return;
      }

      props.onBlur?.(event);

      onApplyValue(event);

      //prevents shift with ios keyboard
      window.scrollTo(0, 0);

      // after a moment, check for refocusing
      // the idea here is that if the last input loses focus, it will
      // attempt to cycle the input focus back to the top. This will
      // only happen once, as if it fails to find a focus point, it
      // should let whatever has taken control continue to keep focus
      setTimeout(refocusPropertiesPanelInput);
    }
  }

  // handles decrementing a value
  function onIncrement(event) {
    if (!disabled) {
      onStep(1, event);
    }
  }

  // handles incrementing a value
  function onDecrement(event) {
    if (!disabled) {
      onStep(-1, event);
    }
  }

  // focuses the input
  function onSelect(event) {
    if (!disabled) {
      setSelected(true);

      // when not currently selected, select the entire field
      if (!selected) {
        selectAll(event.target);
      }

      ref.current?.focus();
      props.onFocus?.(event);
    }
  }

  function onKeyDown(event) {
    if (disabled) {
      cancelEvent(event);
      return;
    }

    // check for special conditions - only undo if the text is
    // the same as the starting value
    if (isUndoInput(event) && ref.current?.innerText === display?.toString()) {
      handleUndo();
      return;
    }

    if (isRedoInput(event)) {
      handleRedo();
      return;
    }

    if (isEnterKey(event)) {
      cancelEvent(event);
      onApplyValue(event);
    }
  }

  // handle input changes
  function onKeyUp(event) {
    if (disabled) {
      cancelEvent(event);
      return;
    }

    // ignore enter keys
    if (isEnterKey(event)) {
      cancelEvent(event);
    }
    // if escape, then revert
    else if (isEscKey(event)) {
      cancelEvent(event);

      // if they want to know what the value was
      if (props.onCancel) {
        props.onCancel(event);
      }

      // abandoned the value
      replaceInput(display);
      setDisplay(display);

      // deselect
      ref.current?.blur();
    }

    // notify when input changes
    // TODO: this fires on all keyboard events - we should
    // check for actual changes later
    if (props.onInput) {
      // TODO: this might not actually be correct
      // but it's fine for now
      props.onInput(event.target.textContent);
    }
  }

  // stay in sync with the property value
  useEffect(() => onReset(props.value), [props.value]);

  // initial focus
  useEffect(() => {
    if (!focused) {
      ref.current.focus();
    }
  }, [focused]);

  //prevents shift with ios keyboard
  useEffect(() => () => window.scrollTo(0, 0), []);

  const inputCx = classNames('properties-panel--input', {
    disabled: disabled,
    'as-stepper': isStepper && !disabled,
    'with-head': props.head && !props.tail,
    'with-tail': props.tail && !props.head,
    'with-head-and-tail': props.head && props.tail,
    // 'as-workspace-id': isWorkspaceId
  });

  return (
    <div className={inputCx} onClick={onSelect}>
      {isStepper && !disabled && (
        <div
          className='properties-panel--input--decrement'
          onClick={onDecrement}
        >
          <Icon>decrement</Icon>
        </div>
      )}

      {props.head && (
        <div className='properties-panel--input--head'>{props.head}</div>
      )}

      <div className='properties-panel--input--content'>
        {props.prefix && !disabled && (
          <div className='properties-panel--input--prefix'>{props.prefix}</div>
        )}

        <div
          ref={ref}
          contentEditable={!disabled}
          suppressContentEditableWarning={true}
          className={`properties-panel--input--field ${
            isWorkspaceId ? 'as-workspace-id' : ''
          }`}
          onFocus={onSelect}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          type={type}
          {...attrs}
          onBlur={onBlur}
          data-cy={dataCy}
        >
          {display}
        </div>

        {props.suffix && (
          <div className='properties-panel--input--suffix'>{props.suffix}</div>
        )}
      </div>

      {props.tail && (
        <div className='properties-panel--input--tail'>{props.tail}</div>
      )}

      {isStepper && !disabled && (
        <div
          className='properties-panel--input--increment'
          onClick={onIncrement}
        >
          <Icon>increment</Icon>
        </div>
      )}
    </div>
  );
}
