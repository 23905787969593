import { IPoint, Point } from '@shapertools/sherpa-svg-generator/Point';

function cubicBezier(
  t: number,
  P0: IPoint,
  P1: IPoint,
  P2: IPoint,
  P3: IPoint
): Point {
  const u = 1 - t;
  const uu = u * u;
  const uuu = uu * u;
  const tt = t * t;
  const ttt = tt * t;

  return new Point(
    uuu * P0.x + 3 * uu * t * P1.x + 3 * u * tt * P2.x + ttt * P3.x,
    uuu * P0.y + 3 * uu * t * P1.y + 3 * u * tt * P2.y + ttt * P3.y
  );
}

function bezierDerivative(
  t: number,
  P0: IPoint,
  P1: IPoint,
  P2: IPoint,
  P3: IPoint
): IPoint {
  const u = 1 - t;
  return {
    x:
      3 * u * u * (P1.x - P0.x) +
      6 * u * t * (P2.x - P1.x) +
      3 * t * t * (P3.x - P2.x),
    y:
      3 * u * u * (P1.y - P0.y) +
      6 * u * t * (P2.y - P1.y) +
      3 * t * t * (P3.y - P2.y),
  };
}

function curvature(
  t: number,
  P0: IPoint,
  P1: IPoint,
  P2: IPoint,
  P3: IPoint
): number {
  const d1 = bezierDerivative(t, P0, P1, P2, P3);
  const d2 = {
    x:
      6 * (1 - t) * (P2.x - 2 * P1.x + P0.x) + 6 * t * (P3.x - 2 * P2.x + P1.x),
    y:
      6 * (1 - t) * (P2.y - 2 * P1.y + P0.y) + 6 * t * (P3.y - 2 * P2.y + P1.y),
  };

  const cross = d1.x * d2.y - d1.y * d2.x;
  const denom = Math.pow(d1.x * d1.x + d1.y * d1.y, 1.5);

  return denom !== 0 ? Math.abs(cross) / denom : 0;
}

function bezierArcLength(
  P0: IPoint,
  P1: IPoint,
  P2: IPoint,
  P3: IPoint,
  scale: number = 1,
  numSegments: number = 10,
  weightArc: number = 0.7
): number {
  let arcLength = 0;
  let prev = P0;

  for (let i = 1; i <= numSegments; i++) {
    const t = i / numSegments;
    const current = cubicBezier(t, P0, P1, P2, P3);
    arcLength += Math.sqrt(
      (current.x - prev.x) ** 2 + (current.y - prev.y) ** 2
    );
    prev = current;
  }

  // 3. Hybrid Weighted Scale
  return weightArc * arcLength + (1 - weightArc) * scale;
}

export function adaptiveBezierSampling(
  P0: IPoint,
  P1: IPoint,
  P2: IPoint,
  P3: IPoint,
  s?: number
): Point[] {
  const scale = bezierArcLength(P0, P1, P2, P3, s) / 100;
  // console.log('scale: ', scale);
  const numSamples = Math.round(50 * Math.sqrt(scale));
  const tValues: number[] = [];
  const curvatures: number[] = [];

  for (let i = 0; i <= numSamples; i++) {
    const t = i / numSamples;
    tValues.push(t);
    curvatures.push(curvature(t, P0, P1, P2, P3));
  }

  const weights = curvatures.map((c) => 1 + 5 * c);
  const totalWeight = weights.reduce((a, b) => a + b, 0);
  const normalizedWeights = weights.map((w) => w / totalWeight);

  const cumulativeWeights: number[] = [];
  normalizedWeights.reduce(
    // eslint-disable-next-line no-sequences
    (acc, w, i) => ((cumulativeWeights[i] = acc + w), acc + w),
    0
  );
  const finalTValues = [0];
  for (let i = 1; i < numSamples; i++) {
    const t = i / numSamples;
    const interpolatedT =
      cumulativeWeights.findIndex((w) => w >= t) / numSamples;
    finalTValues.push(interpolatedT);
  }
  finalTValues.push(1);

  return finalTValues.map((t) => cubicBezier(t, P0, P1, P2, P3));
}
