import { useLayoutEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

// selectors
import {
  selectGetGroupById,
  selectSvgGroupString,
} from '@/Redux/Slices/CanvasSlice';
import {
  selectSelectedGroupIds,
  selectSelectedPathIds,
} from '@/Redux/Slices/SelectionSlice';
import { selectIsDefaultMode, selectUIMode } from '@/Redux/Slices/UISlice';

// components
import Layer from './Layer';
import EditablePoint from './Polyline/EditablePoint';
import { EditableShape } from './Polyline/EditableShape';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import SvgGroupCls from '../State/Helpers/SvgGroup';
import UIState from '../State/UIState';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

type Props = {
  className?: string;
  ui: UIState;
  group: SvgGroupCls;
  selected: boolean;
};

export default function SvgGroup(props: Props) {
  const { ui, group } = props;
  const getGroupById = useSelector(selectGetGroupById);
  const svgGroup = getGroupById(group.id);
  const selectedPathIds = useSelector(selectSelectedPathIds);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);
  const mode = useSelector(selectUIMode);
  const selected = selectedGroupIds.includes(group.id);
  const svg = useSelector(selectSvgGroupString(group.data), shallowEqual);
  const isDefaultMode = useSelector(selectIsDefaultMode);
  const isPoint = group.tool?.type === Shape.POINT;
  const isShape = group.tool?.type === Shape.SHAPE;

  const pathType =
    svgGroup.type?.toLowerCase() || PATH_TYPE.DESIGN.toLowerCase();

  // TODO: this is not ideal, but find the selected paths
  // inside this layer and mark them as selected - manipulate
  // the DOM to avoid rendering a new string when selection changes
  // consider moving the ID generation to somewhere else
  function updatePathSelections() {
    // Shapes created by draw tool have a different structure.
    // we need to find the parent ID of any selected draw tool shapes
    // and add that group to the selection list.
    const selectedDrawToolShapePaths = selectedPathIds
      .map((item) => {
        const shapegroup = getGroupById(item.groupId);
        const parent = shapegroup?.tool?.params?.belongsTo;
        if (!parent) {
          return undefined;
        }
        const parentGroup = getGroupById(parent);
        if (!parentGroup?.basePathSet?.[0]?.id) {
          return undefined;
        }
        return {
          groupId: parentGroup.id,
          pathId: parentGroup.basePathSet[0].id,
        };
      })
      .filter((item) => item !== undefined);

    const selection = [...selectedPathIds, ...selectedDrawToolShapePaths].map(
      (item) => `pathGroup-sg-${item.groupId}-pg-${item.pathId}`
    );
    const groupSelection = [
      ...new Set(
        [...selectedPathIds, ...selectedDrawToolShapePaths].map(
          (i) => i.groupId
        )
      ),
    ];
    document.querySelectorAll(`#sg-${group.id} .pathGroup`).forEach((el) => {
      const action = (() => {
        if (mode === 'default') {
          return groupSelection.some((g) => el.id.includes(g))
            ? 'add'
            : 'remove';
        }
        return selection.includes(el.id) ? 'add' : 'remove';
      })();
      el.classList[action]('selected');
    });
  }

  // refresh when the UI changes
  useLayoutEffect(updatePathSelections);

  if (isPoint && isDefaultMode) {
    return (
      <EditablePoint
        group={group}
        ui={ui}
        selected={selected}
        isOnlySelection={selected && selectedPathIds.length === 1}
      />
    );
  }

  if (isShape && isDefaultMode && group.data?.tool?.params?.points?.length) {
    return <EditableShape group={group} ui={ui} selected={selected} />;
  }

  return (
    <Layer
      id={group.id}
      className={`svg-group ${selected ? 'selected' : ''} ${pathType} ${
        props.className || ''
      }`}
      x={group.cx}
      y={group.cy}
      rotation={group.rotation}
      stretchMatrix={group.stretchMatrix}
      svgGroup={svg}
    />
  );
}
