import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import GlobalCssHelper from '@/Helpers/GlobalCssHelper';
import { useSwitchMobileView } from '@/Utility/react';

// components
import CanvasActionButton from '@/Components/CanvasActionMenu/CanvasActionMenuButton';
import { selectIsMobile } from '@/Redux/Slices/UISlice';
import { useSelector } from 'react-redux';

const stackedButtonHelper = new GlobalCssHelper('stacked-button-state', {
  breakpoint: 'mobile',
});

export type Button = {
  id: string;
  icon: string;
  label: string;
  enabled: boolean;
  toggle: () => void;
  i18nKey: string;
};

type Props = {
  id: string;
  buttons: Button[];
  dataCy: string;
};

export default function StackedButton({ id, buttons, dataCy }: Props) {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useSelector(selectIsMobile);

  const buttonCx = classNames(
    'component--stacked-button',
    `button-count-${buttons.length}`,
    expanded && 'expanded'
  );

  function reset() {
    setExpanded(false);
    stackedButtonHelper.clear();
  }

  // when unmounting, clear any button states
  useEffect(() => {
    return reset;
  }, []);

  useSwitchMobileView((view: string) => {
    // always disable expanded when switching to desktop view
    if (view === 'desktop') {
      reset();
    }
  });

  // handle hiding other canvas menu buttons
  // TODO: this might be too aggressive since it could
  // affect other menus. Maybe consider other approaches
  function onExpandMobile() {
    const isExpanded = !expanded;

    if (isExpanded) {
      stackedButtonHelper.assign({
        '.canvas-action-menu > .canvas-action-menu--item': {
          display: 'none',
        },
        // other stacked buttons also need to be hidden
        '.component--stacked-button:not(.expanded)': {
          display: 'none',
        },
        [`.canvas-action-menu > [data-action='${id}']`]: {
          display: 'grid !important',
        },
      });
    } else {
      stackedButtonHelper.clear();
    }

    setExpanded(isExpanded);
  }

  // generates all buttons
  function renderButtons(attrs?: { 'data-mobile'?: boolean }) {
    return buttons.map((action, i) => (
      <CanvasActionButton
        key={i}
        icon={action.icon || action.id}
        onClick={() => {
          reset();
          action.toggle();
        }}
        attrs={{ 'data-action': action.id, 'data-cy': action.i18nKey }}
        i18nKey={action.i18nKey}
        {...attrs}
      >
        {action.label}
      </CanvasActionButton>
    ));
  }

  // gets the default button for mobile
  const mobileActivationButton = buttons[buttons.length - 1];

  return (
    <div className={buttonCx} data-cy={dataCy}>
      {!isMobile && (
        <div className='component--stacked-button--default'>
          {renderButtons()}
        </div>
      )}

      {isMobile && (
        <div className='component--stacked-button--mobile'>
          {expanded && (
            <>
              {renderButtons({ 'data-mobile': true })}

              <CanvasActionButton
                icon='close'
                onClick={onExpandMobile}
                i18nKey={'close'}
                className='light'
              >
                Close
              </CanvasActionButton>
            </>
          )}

          {!expanded && (
            <CanvasActionButton
              icon={mobileActivationButton.icon || mobileActivationButton.id}
              onClick={onExpandMobile}
              i18nKey={mobileActivationButton.i18nKey}
            >
              {mobileActivationButton.label}
            </CanvasActionButton>
          )}
        </div>
      )}
    </div>
  );
}
